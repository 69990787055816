import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { Link, withI18next } from "gatsby-plugin-i18next"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { I18n } from "react-i18next"
import Layout from "../components/layout"
import React from "react"
import SEO from "../components/seo"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { graphql } from "gatsby"
import { navigate } from "gatsby-link"

function Contact() {
  const [name, setName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [subject, setSubject] = React.useState("")
  const [message, setMessage] = React.useState("")
  const [botField, setBotField] = React.useState("")
  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }
  function handleSubmit(e) {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        name,
        email,
        subject,
        message,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }
  return (
    <I18n>
      {t => (
        <Layout>
          <SEO title={t("holbogdoh")} />
          <div className="section bg-dark-blue mt-4">
            <Container className="height-md-30">
              <Row>
                <Col md="8" lg="7" xl="6">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb p-0 bg-dark-blue bg-transparent">
                      <li className="breadcrumb-item">
                        <Link to="/" className="text-white">
                          {t("home")}
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/contact" className="text-white">
                          {t("holbogdoh")}
                        </Link>
                      </li>
                    </ol>
                  </nav>
                  <h1 className="display-5 text-white">
                    {t("holbogdoh")} <FontAwesomeIcon icon={faPhone} />
                  </h1>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="section">
            <Container>
              <Row className="justify-content-between align-items-center">
                <Col md="6" lg="5">
                  <h4>{t("mnca_full")}</h4>
                  <address className="h6 d-block">{t("location")}:</address>
                  <div className="mb-3">
                    <span className="d-block lead">
                      {t("email")} <a href="#">mnca@mongolia.coop</a>
                    </span>
                    <span className="d-block lead">
                      {t("phone")} +976-11-350-232
                    </span>
                  </div>
                  <h4>{t("mctic_full")}:</h4>
                  <address className="h6 d-block">{t("location")}</address>
                  <div className="mb-3">
                    <span className="d-block lead">
                      {t("email")} <a href="#">mctic@mongolia.coop</a>
                    </span>
                    <span className="d-block lead">
                      {t("phone")} +976-11-350-232
                    </span>
                  </div>
                </Col>
                <Col md="6">
                  <Form
                    name="contact"
                    method="post"
                    action="/thanks"
                    onSubmit={handleSubmit}
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    id="contactform"
                  >
                    {/* FOR SPAM FILTERING */}
                    <input type="hidden" name="form-name" value="contact" />
                    <p hidden>
                      <label>
                        Don’t fill this out:{" "}
                        <input
                          name="bot-field"
                          value={botField}
                          onChange={e => setBotField(e.target.value)}
                        />
                      </label>
                    </p>
                    {/* END FOR SPAM FILTERING */}
                    <Row>
                      <Form.Group controlId="Name" className="col col-lg-6">
                        <Form.Control
                          type="text"
                          placeholder={t("contact_name")}
                          name="name"
                          value={name}
                          onChange={e => setName(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group className="col col-lg-6">
                        <Form.Control
                          placeholder={t("contact_email")}
                          type="email"
                          name="email"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          id="email"
                        />
                      </Form.Group>
                    </Row>
                    <Form.Group>
                      {" "}
                      <Form.Control
                        placeholder={t("contact_subject")}
                        type="text"
                        name="subject"
                        id="subject"
                        value={subject}
                        onChange={e => setSubject(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        as="textarea"
                        placeholder={t("contact_message")}
                        name="message"
                        id="body"
                        style={{ width: 100 + "%" }}
                        rows="10"
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                      />
                    </Form.Group>
                    <div className="column one">
                      <Button variant="primary" type="submit">
                        {t("ilgeeh")}
                      </Button>
                    </div>
                  </Form>
                </Col>
              </Row>
            </Container>
          </div>
        </Layout>
      )}
    </I18n>
  )
}

export default withI18next()(Contact)

export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
  }
`
